export const BACKEND_URL = 'https://api.app.background.network'
export const ENVIRONMENT = 'PRODUCTION'
export const SLACK_CLIENT_ID = '1474767237222.6101436624023'
export const HUBSPOT_CONNECT_LINK = 'https://app-eu1.hubspot.com/oauth/authorize?client_id=66c850d0-f4ca-4012-b976-a1432f1cf3e6&redirect_uri=https://app.brieflyai.com/settings/hubspot_auth_callback&scope=crm.schemas.contacts.write%20crm.objects.owners.read%20crm.objects.contacts.write%20crm.objects.companies.write%20crm.objects.companies.read%20crm.objects.deals.read%20crm.schemas.contacts.read%20crm.objects.deals.write%20crm.objects.contacts.read'
export const SALESFORCE_CONNECT_LINK = 'https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=3MVG9Rr0EZ2YOVMaC.MIyf_VRGOK5qdJgoB0jFAYLC67LW0dGUfi527quwDOH0mQw..yq31.iXN.pIvq7iJDP&redirect_uri=https://app.brieflyai.com/settings/salesforce_callback'
export const EVENT_TRACKING_URL = 'https://logs.collector.solarwinds.com/v1/log';
export const EVENT_TRACKING_TOKEN = 'Vyrg4SSGfivCdKuVcM339xCu-CXt';
export const CHROME_EXTENSION_ID = "bjmgcelbpkgmofiogkmleblcmecflldk"
export const I18N_SUPPORT = true;
export const LOCIZE_APP_ID = "78e14b55-09fb-4fec-85c9-4c2226f91448"
export const LOCIZE_VERSION = "prod"
export const EXTENSION_URL = "https://chromewebstore.google.com/detail/briefly-ai-meeting-summar/bjmgcelbpkgmofiogkmleblcmecflldk";
export const WELCOME_URL = 'https://www.brieflyai.com/welcome-to-briefly';

export default {
  BACKEND_URL,
  ENVIRONMENT,
  SLACK_CLIENT_ID,
  HUBSPOT_CONNECT_LINK,
  SALESFORCE_CONNECT_LINK,
  EVENT_TRACKING_URL,
  EVENT_TRACKING_TOKEN,
  CHROME_EXTENSION_ID,
  I18N_SUPPORT,
  EXTENSION_URL,
  WELCOME_URL,
}
