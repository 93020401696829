import styled from 'styled-components';
import { Button, colors } from '../../theme';

export const LinkButton = styled(Button)`
  display: flex;
  gap: 5px;
  text-decoration: none;
  color: #FFFFFF !important;
`;

export const Page = styled.div`
  background-color: ${colors.gray900};
  height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  a {
    color: #E44867;
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 15px;
`;

export const Message = styled.span`
  max-width: 680px;
  text-align: center;
  line-height: 1.4;
`;


