import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import "./i18n/config";
import Root from "./components/Root";
import { loader as rootLoader } from "./components/loaders/rootLoader";
import utils from './utils/genericUtils';
import Meeting from './components/Meeting';
import Settings from './components/Settings';
import HubspotAuthCallback from './components/settingsTabs/HubspotAuthCallback';
import SalesforceAuthCallback from './components/settingsTabs/SalesforceAuthCallback';
import ChromeExtensionRedirect from './components/ChromeExtensionRedirect';
import UploadTranscript from './components/UploadTranscript';
import ZoomOAuth from './components/ZoomOAuth';
import InjectUser from './components/InjectUser';
import * as Sentry from "@sentry/react";
import { ENVIRONMENT } from './config/config';

import Onboarding from "./components/Onboarding";
import OnboardingConnecting from "./components/OnboardingConnecting";
import OnboardingFinalize from "./components/OnboardingFinalize";
import GoogleProvider from "./components/Providers/GoogleProvider";
import OnboardingNotUser from "./components/OnboardingNotUser";
import SSOSamlHandler from "./components/SSOSamlHandler";
import PostExtensionInstall from "./components/PostExtensionInstall";

import 'react-tooltip/dist/react-tooltip.css';
import "react-datepicker/dist/react-datepicker.css";
import "./react-datepicker-extended.css";


if (ENVIRONMENT !== "development") {
  Sentry.init({
    dsn: "https://edbf0c3ea16b02282d63122798c7b1ac@o1219425.ingest.sentry.io/4505743673655296",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          "https:api.app.background.network",
          "https:api.staging.app.background.network",
        ],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: ENVIRONMENT,
  });
}

const isBrieflySuccess = utils.isBrieflySuccess();


const router = createBrowserRouter([
  {
    path: "chrome_extension_redirect",
    element: <ChromeExtensionRedirect />,
  },
  {
    path: "onboarding",
    loader: rootLoader,
    element: <Onboarding />
  },
  {
    path: "sso/saml/:refresh_token/:access_token",
    element: <SSOSamlHandler />,
  },
  {
    path: "install-success",
    element: <PostExtensionInstall />
  },
  {
    path: "onboarding/notuser",
    loader: rootLoader,
    element: <OnboardingNotUser />
  },
  {
    path: "onboarding/connecting",
    loader: rootLoader,
    element:
      <GoogleProvider>
        <OnboardingConnecting />
      </GoogleProvider>
    ,
  },
  {
    path: "onboarding/finalize",
    loader: rootLoader,
    element: <OnboardingFinalize />,
  },
  {
    path: "/",
    element: <Root />,
    // errorElement: <ErrorPage />,
    loader: rootLoader,
    children: isBrieflySuccess ? [
      {
        path: "inject-user/:email",
        element: <InjectUser />,
      }
    ] : [
      {
        path: "upload-transcript",
        element: <UploadTranscript />
      },
      {
        path: "meeting/:meetingId",
        element: <Meeting />,
      },
      {
        path: "zoom-auth",
        element: <ZoomOAuth />,
      },
      {
        path: "auth-zoom",
        element: <ZoomOAuth />,
      },
      {
        path: "settings/integrations/slack",
        element: <Settings isManageSlackMode={true} />
      },
      {
        path: "settings/integrations/hubspot",
        element: <Settings isManageHubspotMode={true} />
      },
      {
        path: "settings/integrations/salesforce",
        element: <Settings isManageSalesforceMode={true} />
      },
      {
        path: "settings/integrations/zoom",
        element: <Settings isManageZoom={true} />
      },
      {
        path: "settings/hubspot_auth_callback",
        element: <HubspotAuthCallback />
      },
      {
        path: "settings/salesforce_callback",
        element: <SalesforceAuthCallback />
      },
      {
        path: "settings/zoom-callback",
        element: <Settings isZoomCallback={true} />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "settings/:tab",
        element: <Settings />,
      },
      {
        path: "inject-user/:email",
        element: <InjectUser />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Suspense fallback={"Loading..."}>
      <RouterProvider router={router} />
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
