import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { apiGet } from '../../utils/genericUtils';
import { WELCOME_URL, EXTENSION_URL } from '../../config/config';
import { ThreeDots } from 'react-loader-spinner';
import TopNav from '../TopNav';
import { ExternalLinkIcon } from '../Icons';
import { ViewState } from './types';
import { Container, LinkButton, Page, Message } from './style';
import { useTranslation } from 'react-i18next';
import signInToExtensionOrThrow, { ExtensionNotFoundError } from '../../utils/signInToExtensionOrThrow';

export default function SSOSamlHandler() {
  const { t } = useTranslation();
  const [viewState, setViewState] = useState<ViewState>('loading');
  const navigate = useNavigate()
  const params = useParams()

  const loginToExtension = (successViewState: ViewState) => {
    setViewState('loading')
    const { access_token: accessToken, refresh_token: refreshToken } = params;
    if (typeof accessToken !== 'string') {
      throw new Error('Missing access token');
    }
    if (typeof refreshToken !== 'string') {
      throw new Error('Missing refresh token');
    }

    var key = "user-refresh-token"
    localStorage.setItem(key, refreshToken);
    localStorage.setItem('user-access-token', accessToken);
    apiGet({ path: '/get_user' })
      .then(async (userResponse: Record<string, unknown>) => {
        const user_data_key = 'current-user-data'

        localStorage.setItem(user_data_key, JSON.stringify(userResponse))
        localStorage.setItem("LOGGED_IN_AT_LEAST_ONCE_KEY", '1')

        await signInToExtensionOrThrow({ accessToken, refreshToken });

        setViewState(successViewState);
      }).catch((error: ExtensionNotFoundError | TypeError | Error) => {
        if (error instanceof TypeError || error instanceof ExtensionNotFoundError) {
          return setViewState('install_extension_notice');
        }
        setViewState('unknown_error');
        throw error;
      });
  }

  useEffect(() => {
    loginToExtension('login_pass_through');
  }, [])

  useEffect(() => {
    if (viewState === 'login_pass_through') navigate('/');
  }, [viewState])

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      loginToExtension(viewState === 'install_extension_notice' ? 'installed' : 'login_pass_through');
    }
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [viewState]);

  return <Page>
    <TopNav />
    <Container id="content">
      {viewState === 'loading' && (
        <ThreeDots
          height="20"
          width="20"
          radius="9"
          color="white"
          ariaLabel="three-dots-loading"
        />
      )}
      {viewState === 'installed' && (
        <>
          <Message>
            {t('sso.install-extension.installed', 'Thank you for downloading Briefly. Now, you can transcribe and summarize your calls with AI. To learn more about the app, check out our welcome page.')}
          </Message>
          <div>
            <LinkButton as="a" href={WELCOME_URL} $small>
              {t('sso.install-extension.installed-cta', 'Welcome Page')}
            </LinkButton>
          </div>
        </>
      )}

      {viewState === 'unknown_error' && (
        <div>{t('sso.unknown-error', 'Oops, there was an issue.')}</div>
      )}

      {viewState === 'install_extension_notice' && (
        <>
          <span>
            {t('sso.install-extension.download', 'Thank you for signing up for Briefly. Now download the Chrome extension.')}
          </span>
          <div>
            <LinkButton as="a" href={EXTENSION_URL} target="_blank" $small>
              {t('sso.install-extension.cta', 'Install Briefly')}
              <ExternalLinkIcon />
            </LinkButton>
          </div>
        </>
      )}
    </Container>
  </Page>
}
